<template>
  <div>

    <semi-form-header
      :backLink="{ name: 'adminHome' }"
      backLinkText="к списку разделов"
    >
      Конфиги
    </semi-form-header>

    <div class="form-inline d-flex mb-4">
      <div class="form-group flex-fill mr-2">
        <input
          type="text"
          class="form-control search-input"
          placeholder="Введите название конфига"
          :disabled="true || showConfigsSpinner || getConfigs.length === 0"
          ref="search_box">
          <!-- disabled while feature not implemented on backend -->
      </div>
    </div>

    <spinner line-fg-color="#89609E" :speed="1.2" class="py-1" v-show="showConfigsSpinner"/>

    <table class="table table-hover" v-show="!showConfigsSpinner && getConfigs.length !== 0">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Название</th>
          <th scope="col">Описание</th>
          <th scope="col">Обновлен</th>
        </tr>
      </thead>
      <tbody>
        <router-link
          :to="{ name: 'adminConfigsEdit', params: { configId: config.id } }"
          tag="tr"
          v-for="config in getConfigs"
          :key="config.id"
          class="table-row"
        >
          <th scope="row">{{ config.id }}</th>
          <td>{{ config.name }}</td>
          <td><span class="text-muted">{{ config.description }}</span></td>
          <td><span class="text-muted">{{ config.updated_at }}</span></td>
        </router-link>
      </tbody>
    </table>

    <p class="text-muted" v-show="!showConfigsSpinner && getConfigs.length === 0">
      Тут пока нет ни одного конфига
    </p>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import SemiFormHeader from '@/components/admin/FormHeader'

export default {
  components: {
    Spinner,
    SemiFormHeader
  },
  computed: {
    ...mapGetters({
      getConfigs: 'adminConfigs/getConfigs',
      showConfigsSpinner: 'adminConfigs/showConfigsSpinner'
    })
  },
  mounted () {
    this.$refs.search_box.focus()
    this.$store.dispatch('adminConfigs/fetchConfigs')
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  width: 100%;
}
.table-row {
  cursor: pointer;
}
</style>
